<script>
import detailTransactionAll from './detail-transaction-all'

export default {
  props: {
    user: Object
  },
  components: {
    detailTransactionAll
  },
  data() {
    return {
      content: 'detailTransactionAll',
    }
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<template>
  <div>
    <div class="p-2">
      <detailTransactionAll :parent="user" :searchByParent="true" ></detailTransactionAll>
    </div>
  </div>
</template>

<script>
import Games from '../setting/games'
export default {
  props: {
    user: Object
  },
  components: {
    Games
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<template>
  <Games :user="user" />
</template>

<script>
import All from '../transaction/all'
export default {
  props: {
    parent: Object
  },
  components: {
    All
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<template>
  <div>
    <All :parent="parent" :searchByParent="true"  />
  </div>
</template>

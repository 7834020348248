<script>
import Money from '../report/sub-money'

export default {
  props: {
    user: Object
  },
  components: {
    Money
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<template>
  <div>
    <Money :user="user" :search-by-user="true"/>
  </div>
</template>

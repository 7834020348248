<script>
import LogActivity from '../report/log-activity'
export default {
  props: {
    user: Object
  },
  components: {
    LogActivity
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<template>
  <div>
    <LogActivity :user="user" :search-by-user="true" />
  </div>
</template>

<template>
  <span class="badge bg-primary p-1" v-if="record.gameCategoryKey === 'casino'">카지노</span>
  <span class="badge bg-success p-1" v-if="record.gameCategoryKey === 'hotel'">호텔</span>
  <span class="badge bg-info p-1" v-if="record.gameCategoryKey === 'slot'">슬롯</span>
  <span class="badge bg-warning p-1" v-if="record.gameCategoryKey === 'bomb'">폭탄</span>
</template>

<script>
export default {
  props: {
    record: { type: Object }
  },
  methods: {

  },
  computed: {

  }
}
</script>

<script>
import Api from '../api/check'
export default {
  props: {
    user: Object
  },
  components: {
    Api
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<template>
  <div>
    <Api :parent="user" :search-by-parent="true" />
  </div>
</template>

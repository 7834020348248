<script>
import Vendors from '../setting/vendors'
export default {
  props: {
    user: Object
  },
  components: {
    Vendors
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<template>
  <Vendors :user="user" />
</template>

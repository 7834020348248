<template>
  <span class="badge me-1" :class="{'bg-primary': useSeamless === 1, 'bg-success' : useSeamless === 0}">{{ useSeamless === 1 ? $t('심리스') : $t('머니이동')}}</span>
</template>

<script>
  export default {
    props: {
      useSeamless: { type: Number }
    }
  }
</script>

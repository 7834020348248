<script>

export default {
  props: {
    user: Object
  },
  components: {

  },
  data() {
    return {
      working: true,
      preloaderFlag: null,
      validBet: 0,
      today: {
        bet: 0,
        win: 0,
        draw: 0,
        cancel: 0,
        winlose: 0,
        validBet: 0
      },
      yesterday: {
        bet: 0,
        win: 0,
        draw: 0,
        cancel: 0,
        winlose: 0,
        validBet: 0
      },
      month: {
        bet: 0,
        win: 0,
        draw: 0,
        cancel: 0,
        winlose: 0,
        validBet: 0,
        year: '',
        month: ''
      },
      date: '',
      includeBelow: false
    }
  },
  mounted() {
    const self = this
    setTimeout(function() {
      self.getStatBetToday()
      self.getStatBetYesterday()
      self.getStatBetMonth()
    }, 1000)
  },
  methods: {
    loadData : async function(){
      this.getStatBetToday()
      this.getStatBetYesterday()
      this.getStatBetMonth()
    },
    getStatBetToday: async function () {
      try {
        let t = new Date()

        let beginDate = new Date(t.formatUTC('YYYY-MM-DD 00:00:00'))
        let endDate = new Date(t.formatUTC('YYYY-MM-DD 00:00:00'))
        endDate.setDate(endDate.getDate() + 1)

        beginDate = beginDate.toISOString()
        endDate = endDate.toISOString()

        const result = await this.$API.statistic.getStatBetDate(beginDate, endDate, { user: this.user.id, includeBelow: this.includeBelow })
        if (result.code !== 0) {
          return alert(result.msg)
        }

        const betSum = result.document.betSum.floatVal(0)
        const winSum = result.document.winSum.floatVal(0)
        const drawSum = result.document.drawSum.floatVal(0)
        const cancelSum = result.document.cancelSum.floatVal(0)

        this.today.bet = betSum
        this.today.win = winSum
        this.today.draw = drawSum
        this.today.cancel = cancelSum

        // 유효 배팅금
        this.today.validBet = betSum - drawSum - cancelSum
        this.today.winlose = (this.today.validBet - this.today.win)
      } catch(e) {
        this.$log.error(e)
      }
    },
    getStatBetYesterday: async function () {
      try {
        let t = new Date()

        let beginDate = new Date(t.formatUTC('YYYY-MM-DD 00:00:00'))
        beginDate.setDate(beginDate.getDate() - 1)
        let endDate = new Date(t.formatUTC('YYYY-MM-DD 00:00:00'))
        endDate.setDate(endDate.getDate())

        beginDate = beginDate.toISOString()
        endDate = endDate.toISOString()

        const result = await this.$API.statistic.getStatBetDate(beginDate, endDate, { user: this.user.id, includeBelow: this.includeBelow })
        if (result.code !== 0) {
          return alert(result.msg)
        }

        const betSum = result.document.betSum.floatVal(0)
        const winSum = result.document.winSum.floatVal(0)
        const drawSum = result.document.drawSum.floatVal(0)
        const cancelSum = result.document.cancelSum.floatVal(0)

        this.yesterday.bet = betSum
        this.yesterday.win = winSum
        this.yesterday.draw = drawSum
        this.yesterday.cancel = cancelSum

        // 유효 배팅금
        this.yesterday.validBet = betSum - drawSum - cancelSum
        this.yesterday.winlose = (this.yesterday.validBet - this.yesterday.win)
      } catch(e) {
        this.$log.error(e)
      }
    },
    getStatBetMonth: async function () {
      try {
        let t = new Date()

        this.month.year = t.getFullYear()
        this.month.month = (t.getMonth() + 1).zf(2)

        let beginDate = new Date(t.formatUTC('YYYY-MM-01 00:00:00'))
        let endDate = new Date(t.formatUTC('YYYY-MM-DD 00:00:00'))
        endDate.setDate(endDate.getDate() + 1)

        beginDate = beginDate.toISOString()
        endDate = endDate.toISOString()

        // console.log(beginDate, endDate)

        const result = await this.$API.statistic.getStatBetDate(beginDate, endDate, { user: this.user.id, includeBelow: this.includeBelow })
        if (result.code !== 0) {
          return alert(result.msg)
        }

        const betSum = result.document.betSum.floatVal(0)
        const winSum = result.document.winSum.floatVal(0)
        const drawSum = result.document.drawSum.floatVal(0)
        const cancelSum = result.document.cancelSum.floatVal(0)

        this.month.bet = betSum
        this.month.win = winSum
        this.month.draw = drawSum
        this.month.cancel = cancelSum

        // 유효 배팅금
        this.month.validBet = betSum - drawSum - cancelSum
        this.month.winlose = (this.month.validBet - this.month.win)
      } catch(e) {
        this.$log.error(e)
      }
    }
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-10">
    </div>
    <div class="col-md-2">
      <b-form-checkbox v-model="includeBelow" switch size="lg"  @change="loadData">
        하부포함
      </b-form-checkbox>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <b-card bg-variant="primary" class="text-white-50">
        <h5 class="mt-0 mb-4 text-white">
          <i class="mdi mdi-alert-outline me-3"></i> 회원정보
        </h5>
        <hr />
        <p class="mb-0"><span class="d-inline-block w-25">캐쉬</span> <span class="text-white" v-if="user">{{ user.cash.formatThousands() }}</span></p>
        <p class="mb-0"><span class="d-inline-block w-25">상태</span> <span class="text-white" v-if="user">{{ user.state }}</span></p>
        <p class="mb-0"><span class="d-inline-block w-25">Prefix</span> <span class="text-white" v-if="user">{{ user.prefix }}</span></p>
        <p class="mb-0"><span class="d-inline-block w-25">최종로그인</span> <span class="text-white" v-if="user && user.loginAt">{{ new Date(user.loginAt).format('YYYY-MM-DD HH:mm:ss')}}</span></p>
        <p class="mb-0"><span class="d-inline-block w-25">가입일</span> <span class="text-white" v-if="user">{{ new Date(user.createdAt).format('YYYY-MM-DD HH:mm:ss')}}</span></p>
      </b-card>
    </div>
    <div class="col-md-3">
      <b-card bg-variant="success" class="text-white-50">
        <h5 class="mt-0 mb-4 text-white">
          <i class="mdi mdi-alert-outline me-3"></i> 어제 배팅내역
        </h5>
        <hr />
        <p class="mb-0">배팅 <span class="badge text-white float-end">{{ yesterday.bet.formatThousands() }}</span></p>
        <p class="mb-0">승 <span class="badge text-white float-end">{{ yesterday.win.formatThousands() }}</span></p>
        <p class="mb-0">무 <span class="badge text-white float-end">{{ yesterday.draw.formatThousands() }}</span></p>
        <p class="mb-0">취소 <span class="badge text-white float-end">{{ yesterday.cancel.formatThousands() }}</span></p>
        <p class="mb-0">수익금 <span class="badge text-white float-end">{{ yesterday.winlose.formatThousands() }}</span></p>
      </b-card>
    </div>
    <div class="col-md-3">
      <b-card bg-variant="info" class="text-white-50">
        <h5 class="mt-0 mb-4 text-white">
          <i class="mdi mdi-alert-outline me-3"></i> 금일 배팅내역
        </h5>
        <hr />
        <p class="mb-0">배팅 <span class="badge text-white float-end">{{ today.bet.formatThousands() }}</span></p>
        <p class="mb-0">승 <span class="badge text-white float-end">{{ today.win.formatThousands() }}</span></p>
        <p class="mb-0">무 <span class="badge text-white float-end">{{ today.draw.formatThousands() }}</span></p>
        <p class="mb-0">취소 <span class="badge text-white float-end">{{ today.cancel.formatThousands() }}</span></p>
        <p class="mb-0">수익금 <span class="badge text-white float-end">{{ today.winlose.formatThousands() }}</span></p>
      </b-card>
    </div>

    <div class="col-md-3">
      <b-card bg-variant="warning" class="text-white-50">
        <h5 class="mt-0 mb-4 text-white">
          <i class="mdi mdi-alert-outline me-3"></i> {{ month.year }}-{{ month.month }}월 배팅내역
        </h5>
        <hr />
        <p class="mb-0">배팅 <span class="badge text-white float-end">{{ month.bet.formatThousands() }}</span></p>
        <p class="mb-0">승 <span class="badge text-white float-end">{{ month.win.formatThousands() }}</span></p>
        <p class="mb-0">무 <span class="badge text-white float-end">{{ month.draw.formatThousands() }}</span></p>
        <p class="mb-0">취소 <span class="badge text-white float-end">{{ month.cancel.formatThousands() }}</span></p>
        <p class="mb-0">수익금 <span class="badge text-white float-end">{{ month.winlose.formatThousands() }}</span></p>
      </b-card>
    </div>

  </div>
</template>

<script>
import Api from '../setting/api'
export default {
  props: {
    user: Object
  },
  components: {
    Api
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<template>
  <div>
    <Api :user="user" />
  </div>
</template>

<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"

import detailUsers from '../../components/partner/detail-users'
import detailTransaction from '../../components/partner/detail-transaction'
import detailReport from '../../components/partner/detail-report'
import detailSetting from '../../components/partner/detail-setting'
import detailDashboard from '../../components/partner/detail-dashboard'
import LevelBadge from '@/components/badge-level'
import SeamlessBadge from '@/components/badge-seamless'

export default {
  components: {
    Layout,
    PageHeader,
    detailUsers,
    detailTransaction,
    detailReport,
    detailSetting,
    LevelBadge,
    SeamlessBadge,
    detailDashboard
  },
  data() {
    return {
      id: null,
      content: 'detailDashboard',
      user: null
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.init().then()
  },
  methods: {
    init: async function() {
      const result = await this.$API.user.getUser(this.id)
      if (result.code !== 0) {
        return alert(result.msg)
      }
      this.user = result.document
    }
  }
};
</script>

<template>
  <div class="container-fluid mt-2">
    <div class="row">
      <div class="col-lg-12">
        <div class="card shadow shadow-sm">
          <div class="card-header" v-if="user">
            <div>

              <level-badge :partner="user.top" :show-name="true" v-if="user.top &&  ($store.getters['auth/user'].level != 'super' && $store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"></level-badge>
              <level-badge :partner="user.super" :show-name="true" v-if="user.super && ($store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"></level-badge>
              <level-badge :partner="user.master" :show-name="true" v-if="user.master && ($store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"></level-badge>
              <level-badge :partner="user.agent" :show-name="true" v-if="user.agent && ($store.getters['auth/user'].level != 'shop')"></level-badge>
              <level-badge :partner="user.shop" :show-name="true" v-if="user.shop"></level-badge>
            </div>
            <h4 class="card-title" v-if="user">
              <seamless-badge :use-seamless="user.useSeamless"></seamless-badge>
              <level-badge :partner="user"></level-badge>
              {{ $t('user_info', [user.username])}}
              <small>
                (<span>{{ $t('캐쉬') }}: {{ user.cash.floatVal().formatThousands() }}</span>)
              </small>
            </h4>
          </div>
          <div class="card-body">
            <div class="card-header pb-0">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link" :class="{active: content==='detailDashboard'}" href="#" @click="content='detailDashboard'">Dashboard</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{active: content==='detailReport'}" href="#" @click="content='detailReport'">{{ $t('보고서') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{active: content==='detailUsers'}" href="#" @click="content='detailUsers'">{{ $t('회원목록') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{active: content==='detailTransaction'}" href="#" @click="content='detailTransaction'">{{ $t('거래관리') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :class="{active: content==='detailSetting'}" href="#" @click="content='detailSetting'">{{ $t('환경설정') }}</a>
                </li>
              </ul>
            </div>
            <div class="card-body ps-0 pe-0">
              <component :is="content" :user="user"></component>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
  </div>
</template>

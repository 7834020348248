<script>
import detailReportMoney from './detail-report-money'
import detailReportSubMoney from './detail-report-sub-money'
import detailReportLog from './detail-report-log'
import detailReportSettlement from './detail-report-settlement'

export default {
  props: {
    user: Object
  },
  components: {
    detailReportMoney,
    detailReportLog,
    detailReportSettlement,
    detailReportSubMoney
  },
  data() {
    return {
      content: 'detailReportMoney',
    }
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<template>
  <div>
    <ul class="nav nav-tabs mb-2">
      <li class="nav-item">
        <a class="nav-link" :class="{active: content==='detailReportMoney'}" href="#" @click="content='detailReportMoney'">{{ $t('지급/회수') }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{active: content==='detailReportSubMoney'}" href="#" @click="content='detailReportSubMoney'">{{ $t('하부 지급/회수') }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{active: content==='detailReportSettlement'}" href="#" @click="content='detailReportSettlement'">{{ $t('정산') }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{active: content==='detailReportLog'}" href="#" @click="content='detailReportLog'">{{ $t('활동로그') }}</a>
      </li>
    </ul>

    <div class="p-2">
      <component :is="content" :user="user"></component>
    </div>
  </div>
</template>
